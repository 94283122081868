import React from 'react'
import { useNavigate } from "react-router-dom"

export default function ProjectItem(props){
    const { roadway, from, to, id } = props  
    const navigate = useNavigate()

    return (
        <tr onClick={() => navigate("/project/"+id)}>
            <td className='ps-4'>{roadway}</td>
            <td className='ps-4'>{from.length === 0 ? <i>Unavailable</i> : from}</td>
            <td className='ps-4'>{to.length === 0 ? <i>Unavailable</i> : to}</td>
        </tr>
    )
}