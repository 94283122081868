import { createRef, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet";
import ProjectItem from "../components/ProjectItem"
import ProjectInfo from "../assets/projects.json"

export default function Projects(){
    const [sortBy,setSortBy] = useState(['Roadway',false])
    const [projectItems,setProjectItems] = useState([])
    const ref = createRef()
    const { state } = useLocation()
    
    const map = <iframe  title="Precinct Map" style={{ width: "100%", maxHeight: "100%", overflow: "hidden", overflowX: "hidden", overflowY: "hidden" }} height="600" width="1200" src="https://experience.arcgis.com/experience/9b9cbeecaaf1436690e890bc1cba4fd9"/>
    const arrowDown = <i className="bi bi-caret-down-square float-end"/>
    const arrowUp = <i className="bi bi-caret-up-square float-end"/>

    function sortByRoadway(data){
        data.sort((a,b) => {
            a = a.roadway.toLowerCase()
            b = b.roadway.toLowerCase()
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }
    function sortByRoadwayReverse(data){
        data.sort((a,b) => {
            a = a.roadway.toLowerCase()
            b = b.roadway.toLowerCase()
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }
    function sortByFrom(data){
        data.sort((a,b) => {
            a = a.limitsFrom.toLowerCase()
            b = b.limitsFrom.toLowerCase()
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }
    function sortByFromReverse(data){
        data.sort((a,b) => {
            a = a.limitsFrom.toLowerCase()
            b = b.limitsFrom.toLowerCase()
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }
    function sortByTo(data){
        data.sort((a,b) => {
            a = a.limitsTo.toLowerCase()
            b = b.limitsTo.toLowerCase()
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }
    function sortByToReverse(data){
        data.sort((a,b) => {
            a = a.limitsTo.toLowerCase()
            b = b.limitsTo.toLowerCase()
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }

    useEffect(() => {
        if (sortBy[0] === 'Roadway' && sortBy[1] === false) sortByRoadway(ProjectInfo)
        else if (sortBy[0] === 'From' && sortBy[1] === false) sortByFrom(ProjectInfo)
        else if (sortBy[0] === 'To' && sortBy[1] === false) sortByTo(ProjectInfo)
        else if (sortBy[0] === 'Roadway' && sortBy[1] === true) sortByRoadwayReverse(ProjectInfo)
        else if (sortBy[0] === 'From' && sortBy[1] === true) sortByFromReverse(ProjectInfo)
        else if (sortBy[0] === 'To' && sortBy[1] === true) sortByToReverse(ProjectInfo)

        var data = ProjectInfo.map((p) => {
            return (<ProjectItem key={p.id} id={p.id} roadway={p.roadway} from={p.limitsFrom} to={p.limitsTo} />)
        })
        setProjectItems(data)
    },[sortBy])

    useEffect(()=> {
        if(state == null) return;
        if(state.fromProjects === false) return;
        window.scrollTo({left:0, top: ref.current.offsetTop, behavior: 'smooth'})
    },[state,ref])

    const handleClick = (sort) => {
        if (sortBy[0] === sort) setSortBy([sortBy[0],!sortBy[1]])
        else if (sortBy[0] !== sort && sort === 'Roadway') setSortBy(['Roadway',false])
        else if (sortBy[0] !== sort && sort === 'From') setSortBy(['From',false])
        else if (sortBy[0] !== sort && sort === 'To') setSortBy(['To',false])
    }

    return(
        <div>
            <Helmet>
                <meta property="og:description" content="A map and list containing all of the projects in the Lubbock 2022 Street Bond Program." />
                <meta property="og:title" content="Projects | City of Lubbock Street Bond" />
                <meta property="og:url" content="https://www.lubbock2022streetbond.com/projects"/>
            </Helmet>
            <div className="flex-shrink-0 modular min-vh-100">
                <img src={`/assets/downtown${Math.floor(Math.random()*3)}.png`} className="d-block w-100" style={{ objectFit: "cover", height: "300px" }} alt="Projects"/>
                <div className="container">
                    <div className='row my-3'>
                        <div className='col-12'>
                            <h1 style={{ color: "#00354C" }} className="text-start">Project Map</h1>
                            {map}
                        </div>
                    </div>
                    <div ref={ref} className='row my-3 text-start'>
                        <h1 style={{ color: "#00354C" }} className="text-start">Projects</h1>
                        <table className='table table-striped table-hover mx-auto' style={{ width:"90%"}}>
                            <thead>
                                <tr>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('Roadway')}>
                                            <h4>Roadway { sortBy[0] === 'Roadway' ? (sortBy[1] === false) ? arrowDown : arrowUp : "" }</h4>
                                        </div>
                                    </th>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('From')}>
                                            <h4>From { sortBy[0] === 'From' ? (sortBy[1] === false) ? arrowDown : arrowUp : "" }</h4>
                                        </div>
                                    </th>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('To')}>
                                            <h4>To { sortBy[0] === 'To' ? (sortBy[1] === false) ? arrowDown : arrowUp : "" }</h4>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ cursor: "pointer" }} >
                                {projectItems}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}